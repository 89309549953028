import { ApolloProvider } from "@apollo/client";
import loadable from "@loadable/component";
// import CssBaseline from "@mui/material/CssBaseline";
import { PaletteMode } from "@mui/material";
import ProductConfiguratorProvider from "@RHCommerceDev/custom-providers/ProductConfiguratorProvider";
import { getGlobal } from "@RHCommerceDev/utils/Globals/getGlobal";
import { RHNotificationProvider } from "component-rh-notification-modal";
import express from "express";
import type { ClientType } from "graphql-client/client-type";
import useBrand from "hooks-use-brand/useBrand";
import { processEnvServer } from "hooks/useSsrHooks";
import React, { Component, createContext } from "react";
import { KeycloakProviderAdapter } from "utils-keycloak/KeyCloak";
import { BREAKPOINT_SM } from "utils/constants";
import { getIsReactMicrosite } from "utils/getIsReactRoute";
import { useMediaQuery } from "@mui/material";
import memoize from "utils/memoize";
import yn from "yn";
import AvailableCountryProvider from "./AvailableCountryProvider";
import CountrySiteProvider from "./CountrySiteProvider";
import ErrorProvider from "./ErrorProvider";
import SessionProvider from "./SessionProviderV2";
import UserPreferencesProvider from "./UserPreferencesProvider";

const StytchProvider = loadable(
  () =>
    import(
      /* webpackChunkName: "stytch-provider" */ "@RHCommerceDev/stytch-provider"
    )
);

type PropType = { [key: string]: any };

class ErrorBoundary extends Component {
  state: { hasError: boolean };
  constructor(props: PropType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // TODO: Log this to service
    console.log("React Error", error, errorInfo);
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}

const EB = memoize(ErrorBoundary) as React.ComponentType;

export const UseRequestProvider: any = createContext({});
export const UseResponseProvider: any = createContext({});

export interface ProviderProps {
  type?: PaletteMode;
  children: any;
  cookiePersistor?: any;
  req?: express.Request;
  client: ClientType;
}

const env = getGlobal("_env");
const FEATURE_STYTCH = yn(env.FEATURE_STYTCH);

export const Provider: React.FC<ProviderProps> = ({
  type,
  children,
  cookiePersistor = null,
  req = {} as express.Request,
  client
}) => {
  const brand = useBrand();

  // TODO: Is it possible to let CSS drive the grid completely, or
  // are we stuck with changing the theme on a breakpoint basis
  // which may cause heavy rerenders per breakpoint?
  const smDown = processEnvServer
    ? req?.cookies?.PF_EXP?.toLowerCase() === "mobile"
    : useMediaQuery(`(max-width: ${BREAKPOINT_SM}px)`);
  const isMicrosite = getIsReactMicrosite();

  const shouldRenderStytchProvider =
    FEATURE_STYTCH && !processEnvServer && StytchProvider;

  if (isMicrosite) {
    return (
      <EB>
        <UseRequestProvider.Provider value={req}>
          <ApolloProvider client={client}>
            <ErrorProvider>{children}</ErrorProvider>
          </ApolloProvider>
        </UseRequestProvider.Provider>
      </EB>
    );
  }

  return (
    <EB>
      <UseRequestProvider.Provider value={req}>
        <KeycloakProviderAdapter cookiePersistor={cookiePersistor}>
          <ApolloProvider client={client}>
            <UserPreferencesProvider>
              <ErrorProvider>
                <RHNotificationProvider>
                  <ProductConfiguratorProvider>
                    <AvailableCountryProvider>
                      <SessionProvider>
                        <CountrySiteProvider>
                          {shouldRenderStytchProvider ? (
                            <StytchProvider>{children}</StytchProvider>
                          ) : (
                            children
                          )}
                        </CountrySiteProvider>
                      </SessionProvider>
                    </AvailableCountryProvider>
                  </ProductConfiguratorProvider>
                </RHNotificationProvider>
              </ErrorProvider>
            </UserPreferencesProvider>
          </ApolloProvider>
        </KeycloakProviderAdapter>
      </UseRequestProvider.Provider>
    </EB>
  );
};

export default memoize(Provider);
